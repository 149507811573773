<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header-inner">
        <form
          @submit.prevent="(page = 0), getPushMessageList()"
        >
          <div class="filter-card">
            <div class="date-field">
              <label>發送起始日</label>
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="選擇日期"
                v-model="search.startDate"
              />
            </div>

            <div class="date-field">
              <label>發送結束日</label>
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="選擇日期"
                v-model="search.endDate"
              />
            </div>

            <div class="filter-field">
              <label>關鍵字查詢</label>
              <input type="text" placeholder="關鍵字查詢" v-model.trim="search.keyword" />
            </div>

            <button type="submit" class="btn btn-primary btn-sm ml-1">
              查詢
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="page-body">
      <div class="text-right mb-2">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target="#messageModal"
          @click="(message = {}), (accounts = []), $refs.createMessageValidate.reset()"
        >
          新增推播
        </button>
      </div>
      <div class="table-wrap">
        <el-table :data="pushMessageList" :header-cell-style="{fontWeight: 600}" style="width: 100%">
          <el-table-column width="20px" />
          <el-table-column label="標題" prop="title" />
          <el-table-column label="發送日期" prop="pusH_TIME" />
          <el-table-column label="內容" prop="body" align="center" />
          <el-table-column align="center">
            <template slot-scope="scope" v-if="permission === '1'">
              <button
                type="button"
                class="btn btn-warning btn-sm"
                data-toggle="modal"
                data-target="#detailModal"
                @click="getPushMessageDetails(scope.row.guid)"
              >
                檢視紀錄
              </button>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template
              slot-scope="scope"
              v-if="permission === '1' && scope.row.pusH_STATUS === '處理完成'"
            >
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="resendPushMessage(scope.row.guid)"
              >
                重新發送
              </button>
            </template>
          </el-table-column>
          <el-table-column slot="empty">暫無數據</el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="count" @current-change="changePage" />
      </div>
    </div>

    <!-- 新增推播 -->
    <div class="modal fade" id="messageModal" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <ValidationObserver ref="createMessageValidate" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(createPushMessage)">
              <div class="modal-header">
                <h6>推播內容</h6>
              </div>
              <div class="modal-body">
                <div class="field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label>
                      <span>訊息標題<span class="text-red"> (25字)</span></span>
                      <input type="text" maxlength="25" v-model.trim="message.title" />
                      <span class="text-red">{{ errors[0] }}</span>
                    </label>
                  </ValidationProvider>
                </div>

                <div class="field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label>
                      <span>訊息內容</span>
                      <textarea type="text" rows="3" maxlength="200" v-model.trim="message.body" />
                      <span class="text-red">{{ errors[0] }}</span>
                    </label>
                  </ValidationProvider>
                </div>

                <div class="field">
                  <span>挑選推播對象 <span class="text-red">(未挑選則推播給所有對象)</span></span>
                  <el-select
                    class="chooseSelect"
                    filterable
                    remote
                    multiple
                    clearable
                    placeholder="請輸入客戶名稱"
                    :remote-method="getPushMessageCustomerList"
                    :loading="loading"
                    loading-text="查詢中..."
                    no-match-text="無匹配資料"
                    no-data-text="無資料"
                    v-model="accounts"
                  >
                    <el-option
                      v-for="item in customerList"
                      :key="item.account"
                      :label="item.cusT_SNM"
                      :value="item.account"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  關閉
                </button>
                <button type="submit" class="btn btn-primary">
                  發送
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!-- 推播紀錄 -->
    <div class="modal fade" id="detailModal" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6>推播紀錄</h6>
          </div>
          <div class="modal-body">
            <h6>
              <strong> 總共 {{ messageRecord.totalCount }} 筆 </strong>
            </h6>
            <p>
              待發送：{{ messageRecord.notYetCount }} 筆 成功：{{ messageRecord.successCount }} 筆
              失敗：{{ messageRecord.errorCount }} 筆
            </p>

            <template v-if="messageRecord.errorCount > 0">
              <h6><strong>發送失敗列表</strong></h6>
              <el-table
                :data="messageRecord.errorDetails"
                style="width: 100%"
                @selection-change="selectionChange"
              >
                <el-table-column type="selection" width="50px" />
                <el-table-column type="index" width="100px" />
                <el-table-column prop="account" label="帳號" />
                <el-table-column prop="pusH_ERRORS" label="失敗原因" />
              </el-table>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              關閉
            </button>
            <button
              v-if="failedPushMessageList.length > 0"
              type="button"
              class="btn btn-primary"
              @click="resendFailedPushMessage"
            >
              重新發送
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import { mapActions } from "vuex";
import {
  apiGetPostPushMessages,
  apiGetPushMessageCustomerList,
  apiCreatePushMessage,
  apiRunPushMessageTask,
  apiResendPushMessage,
  apiGetPushMessageDetails,
  apiPostResendFailedPushMessage
} from "@/apis/push";

export default {
  name: "PushMessage",

  data() {
    return {
      // 權限(僅能觀看: 0, 可觀看且編輯: 1)
      permission: null,

      // 推播訊息
      message: {},
      // 預推播對象
      accounts: [],

      // 查詢
      search: {},
      // 推播總筆數
      count: 0,

      // 推播紀錄
      messageRecord: {},

      // 失敗推播列表
      failedPushMessageList: [],

      // 推播列表
      pushMessageList: [],
      // 推播對象列表
      customerList: [],
      // 查詢推播對象 loading
      loading: false,

      // 當前分頁
      page: 0
    };
  },
  methods: {
    // 重新發送失敗推播
    resendFailedPushMessage() {
      $("#detailModal").modal("hide");
      this.updateLoading(true);
      apiPostResendFailedPushMessage({
        pushMessageGuid: this.failedPushMessageList[0].pusH_MESSAGE_GUID,
        detailIds: this.failedPushMessageList.map(item => item.id)
      }).then(response => {
        if (response.status === 200) {
          this.updateLoading(false);
          this.failedPushMessageList = [];
          this.$notify({
            title: "成功",
            message: "重新發送失敗推播",
            type: "success",
            duration: 2500
          });
        }
      });
    },

    // 選取失敗推播
    selectionChange(list) {
      this.failedPushMessageList = list;
    },

    // 重新發送推播
    resendPushMessage(messageGuid) {
      this.updateLoading(true);
      apiResendPushMessage({
        pushMessageGuid: messageGuid
      }).then(response => {
        if (response.status === 200) {
          // 公司測試站需加下面這行(美食家站台不需要)
          this.runPushMessageTask();
          // 公司測試站需加上面這行(美食家站台不需要)
          this.updateLoading(false);
          this.$notify({
            title: "成功",
            message: "已重新發送推播",
            type: "success",
            duration: 2500
          });
        }
      });
    },

    // 新增推播
    createPushMessage() {
      $("#messageModal").modal("hide");
      const accounts = this.accounts.length > 0 ? this.accounts : null;
      this.updateLoading(true);
      apiCreatePushMessage({
        ...this.message,
        accounts
      }).then(response => {
        if (response.status === 200) {
          this.updateLoading(false);
          this.$notify({
            title: "成功",
            message: "已發送推播",
            type: "success",
            duration: 2500
          });
          // 公司測試站需加下面這行(美食家站台不需要)
          this.runPushMessageTask();
          // 公司測試站需加上面這行(美食家站台不需要)
          // 美食家站台需加下面這行(公司測試站不需要)
          // this.getPushMessageList();
          // 美食家站台需加上面這行(公司測試站不需要)
        }
      });
    },

    // 模擬排程
    runPushMessageTask() {
      apiRunPushMessageTask().then(response => {
        if (response.status === 200) {
          this.getPushMessageList();
        }
      });
    },

    // 檢視推播紀錄
    getPushMessageDetails(messageGuid) {
      this.updateLoading(true);
      apiGetPushMessageDetails({
        pushMessageGuid: messageGuid
      }).then(response => {
        if (response.status === 200) {
          this.updateLoading(false);
          this.messageRecord = response.data;
        }
      });
    },

    // 取得推播對象
    getPushMessageCustomerList(keyword) {
      if (keyword !== "") {
        this.loading = true;
        apiGetPushMessageCustomerList({
          keyword: keyword
        }).then(response => {
          if (response.status === 200) {
            this.loading = false;
            if (response.data) {
              this.customerList = response.data;
            }
          }
        });
      } else {
        this.customerList = [];
      }
    },

    // 換頁
    changePage(page) {
      this.page = --page;
      this.getPushMessageList();
    },

    // 取得推播列表
    getPushMessageList() {
      this.updateLoading(true);
      apiGetPostPushMessages({
        ...this.search,
        pageIndex: this.page,
        perPage: 10
      }).then(response => {
        if (response.status === 200) {
          this.updateLoading(false);
          if (this.search.endDate < this.search.startDate) {
            this.$notify({
              title: "失敗",
              message: response.data,
              type: "error",
              duration: 2500
            });
          }
          this.count = response.data.count;
          this.pushMessageList = response.data.datas;
        }
      });
    },

    // loading
    ...mapActions(["updateLoading"])
  },

  created() {
    this.getPushMessageList();
    this.permission = localStorage.PUSH_MESSAGE_PERMISSION;
  }
};
</script>

<style lang="scss" scoped>
#messageModal {
  .modal-body {
    label {
      width: 100%;
      margin-bottom: 20px;
    }
    input,
    textarea {
      width: 100%;
      display: block;
      border: none;
      background-color: #fef6ee;
      line-height: 35px;
      border-radius: 5px;
      padding: 0 10px;
    }

    .chooseSelect {
      width: 100%;
      display: block;
    }
  }
}
/deep/ .el-table, .el-table__expanded-cell {
  font-weight: 600;
}
</style>
