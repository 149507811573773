import req from "./https";

// 取得權限
export const apiGetPushMessagePermission = params =>
  req("get", "/PushMessage/GetPushMessagePermission", params);

// 取得推播列表
export const apiGetPostPushMessages = params =>
  req("post", "/PushMessage/PostPushMessages", params);

// 取得推播對象
export const apiGetPushMessageCustomerList = params =>
  req("get", "/PushMessage/GetPushMessageCustomerList", params);

// 新增推播
export const apiCreatePushMessage = params =>
  req("post", "/PushMessage/PostCreatePushMessage", params);

// 模擬排程
export const apiRunPushMessageTask = () => req("post", "/PushMessage/PostRunPushMessageTask");

// 重新發送推播
export const apiResendPushMessage = params =>
  req("post", "/PushMessage/PostResendPushMessage", params);

// 重新發送失敗推播
export const apiPostResendFailedPushMessage = params =>
  req("post", "/PushMessage/PostResendFailedPushMessage", params);


// 檢視紀錄
export const apiGetPushMessageDetails = params =>
req("get", "/PushMessage/GetPushMessageDetails", params);
